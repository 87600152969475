<template>
  <div class="contract-detail">
    <div class="detail-box">
      <div class="left">
        <div class="tab-box">
          <span
            class=" iconfont icon-jibenxinxi"
            style="color:#2370EB;margin:0 0 0 30px"
          ></span>
          <p @click="onSwitch(1)" :class="index == 1 ? 'isColor' : ''">
            基本信息
            <span></span>
          </p>
          <p
            @click="onSwitch(2)"
            v-show="btnP.descAuditHistory"
            :class="index == 2 ? 'isColor' : ''"
          >
            审核历史<span></span>
          </p>
          <p
            @click="onSwitch(3)"
            v-show="btnP.descRelation"
            :class="index == 3 ? 'isColor' : ''"
          >
            关联订单<span></span>
          </p>
        </div>
        <Information v-show="index == 1" ref="information" />
        <History v-show="index == 2" ref="historys" />
        <Relation v-show="index == 3" ref="relation" />
      </div>
    </div>
    <div class="bottom-but">
      <el-button
        round
        type="primary"
        style="background:#2370EB;border-color:#2370EB;color:#fff;"
        size="mini"
        @click="onClose"
        >返回</el-button
      >
    </div>
  </div>
</template>
<script>
import Information from "./information.vue";
import Relation from "./relation.vue";
import History from "./history.vue";
export default {
  components: {
    Information,
    Relation,
    History,
  },
  data() {
    return {
      index: 1,
      btnP: {},
    };
  },
  methods: {
    getData() {
      this.index = 1;
      let btn = JSON.parse(sessionStorage.getItem("isButArr"));
      var btnChild = [];
      for (let i in btn) {
        if (btn[i].route == "details") {
          btnChild = btn[i].children;
        }
      }
      this.drawerTitle = "客户详情";
      if (btnChild && btnChild.length > 0) {
        this.drawer = true;
      }
      let btnP = {};
      for (let i in btnChild) {
        btnP["" + btnChild[i].route] = true;
      }
      this.btnP = btnP;
      this.$refs.information.getData();
      this.$refs.historys.getData();
      this.$refs.relation.getOrder();
    },
    onSwitch(i) {
      this.index = i;
    },
    onClose() {
      this.$emit("close");
      this.$formDetails.commit("emptyData");
    },
  },
  beforeRouteLeave(to, form, next) {
    this.$formDetails.commit("emptyData");
    next();
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.contract-detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .detail-box {
    flex: 1;
    display: flex;
    height: calc(100% - 158px);
  }
  .left {
    flex: 1;
    background: #fff;
    border-radius: 4px;
  }
  .right {
    background: #fff;
    flex: 1;
    border-radius: 4px;
  }
  .bottom-but {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #dee0e3;
    button {
      width: 90px;
    }
  }
  .tab-box {
    display: flex;
    align-items: center;
    height: 59px;
    border-bottom: 1px solid #eee;
    p {
      cursor: pointer;
      font-weight: 500;
      color: #666666;
      font-size: 14px;
      padding: 0 16px;
      height: 100%;
      line-height: 59px;
    }
    .isColor {
      color: #2370eb;
      position: relative;
      span {
        display: block;
        width: 16px;
        height: 3px;
        background: #2370eb;
        border-radius: 1px 1px 0px 0px;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -8px;
      }
    }
  }
  .content-form-box {
    flex: 1;
    overflow: auto;
  }
}
</style>
