var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relation"},[_vm._m(0),_c('div',{staticClass:"content"},[(_vm.data.length > 0)?_vm._l((_vm.data),function(data,index){return _c('el-collapse',{key:data.id,staticStyle:{"padding-left":"28px"}},[_c('el-collapse-item',{attrs:{"title":_vm.title(data),"name":index}},[_c('el-form',{staticStyle:{"flex":"1","margin-top":"20px"},attrs:{"label-position":"left","label-width":"100px"}},[(data.productCategory == 1 || data.productCategory == 2)?_c('el-form-item',{attrs:{"label":"邮箱版本"}},[(data.productCategory == 1)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.version == 1 ? "老版" : data.version == 2 ? "新版" : "- -"))]):_vm._e(),(data.productCategory == 2)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.version == 1 ? "标准版" : data.version == 2 ? "尊享版" : data.version == 3 ? "集团版" : "- -"))]):_vm._e()]):_vm._e(),(
                data.productCategory == 1 ||
                  data.productCategory == 2 ||
                  data.productCategory == 3 ||
                  data.productCategory == 10
              )?_c('el-form-item',{attrs:{"label":"订单类型"}},[(
                  data.productCategory == 1 ||
                    data.productCategory == 2 ||
                    data.productCategory == 10
                )?_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.orderType == 1 ? "新增" : data.orderType == 2 ? "续费" : data.orderType == 3 ? "扩用户" : "- -"))]):_vm._e(),(data.productCategory == 3)?_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.version == 1 ? "腾讯云" : data.version == 2 ? "阿里云" : "- -"))]):_vm._e()]):_vm._e(),(
                data.productCategory == 1 ||
                  data.productCategory == 2 ||
                  data.productCategory == 10
              )?_c('el-form-item',{attrs:{"label":"用户数"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.orderNumber))])]):_vm._e(),(
                (data.productCategory == 1 ||
                  data.productCategory == 2 ||
                  data.productCategory == 10) &&
                  data.orderType != 3
              )?_c('el-form-item',{attrs:{"label":"购买年限"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.orderTerm))])]):_vm._e(),(
                (data.productCategory == 1 || data.productCategory == 2) &&
                  data.orderType != 3
              )?_c('el-form-item',{attrs:{"label":"增补年限"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.supplementTerm))])]):_vm._e(),(
                (data.productCategory == 1 || data.productCategory == 2) &&
                  data.orderType != 3
              )?_c('el-form-item',{attrs:{"label":"下单年限"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.totalTerm))])]):_vm._e(),(data.productCategory == 10)?_c('el-form-item',{attrs:{"label":"vip账号金额"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.vipAmount))])]):_vm._e(),(data.productCategory == 10)?_c('el-form-item',{attrs:{"label":"系统金额"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.systemAmount))])]):_vm._e(),_c('el-form-item',{attrs:{"label":"合计金额"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.amount))])]),_c('el-form-item',{attrs:{"label":"交付状态"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.status == 1 ? "未交付" : data.status == 2 ? "部分交付" : data.status == 3 ? "已交付" : "- -"))])]),_c('el-form-item',{attrs:{"label":"备注"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(data.remark))])])],1)],1)],1)}):[_vm._m(1)]],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_c('span',{staticClass:" iconfont icon-dilanxianxingiconyihuifu_huabanfuben",staticStyle:{"color":"#2370EB","margin":"0 14px 0 30px"}}),_vm._v(" 关联订单 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"empty"},[_c('img',{attrs:{"src":require("@/assets/zanwushuju.png")}}),_c('span',{staticStyle:{"display":"block"}},[_vm._v("暂无数据～")])])}]

export { render, staticRenderFns }