<template>
  <div class="history">
    <div class="history-list" v-for="item in data.contractAuditHistoryList">
      <p>
        <span>审核结果</span>
        <span>{{
          item.status == 8 ? "审核驳回" : item.status == 9 ? "审核通过" : "- -"
        }}</span>
      </p>
      <p>
        <span>审核时间</span>
        <span>{{ item.auditTime }}</span>
      </p>
      <p>
        <span>审核备注</span>
        <span>{{ item.auditRemark ? item.auditRemark : "- -" }}</span>
      </p>
      <i></i>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: [],
    };
  },
  methods: {
    getData() {
      this.data = this.$formDetails.state.data;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.history-list {
  p {
    display: flex;
    padding: 10px 0;
    padding-left: 40px;
    span {
      display: inline-block;
    }
    span:nth-child(1) {
      width: 80px;
      font-size: 14px;
      color: #333;
    }
    span:nth-child(2) {
      font-size: 14px;
      color: #000;
    }
  }
  i {
    display: block;
    border-bottom: 1px dashed #ccc;
    margin: 0 20px;
    padding-top: 20px;
  }
}
</style>
