<template>
  <div class="relation">
    <p class="title">
      <span
        class=" iconfont icon-dilanxianxingiconyihuifu_huabanfuben"
        style="color:#2370EB;margin:0 14px 0 30px"
      ></span>
      关联订单
    </p>
    <div class="content">
      <template v-if="data.length > 0">
        <el-collapse
          v-for="(data, index) in data"
          style="padding-left: 28px;"
          :key="data.id"
        >
          <el-collapse-item :title="title(data)" :name="index">
            <el-form
              style="flex:1;margin-top:20px;"
              label-position="left"
              label-width="100px"
            >
              <el-form-item
                label="邮箱版本"
                v-if="data.productCategory == 1 || data.productCategory == 2"
              >
                <span class="text" v-if="data.productCategory == 1">{{
                  data.version == 1
                    ? "老版"
                    : data.version == 2
                    ? "新版"
                    : "- -"
                }}</span>
                <span class="text" v-if="data.productCategory == 2">{{
                  data.version == 1
                    ? "标准版"
                    : data.version == 2
                    ? "尊享版"
                    : data.version == 3
                    ? "集团版"
                    : "- -"
                }}</span>
              </el-form-item>

              <el-form-item
                label="订单类型"
                v-if="
                  data.productCategory == 1 ||
                    data.productCategory == 2 ||
                    data.productCategory == 3 ||
                    data.productCategory == 10
                "
              >
                <span
                  class="text"
                  v-if="
                    data.productCategory == 1 ||
                      data.productCategory == 2 ||
                      data.productCategory == 10
                  "
                  >{{
                    data.orderType == 1
                      ? "新增"
                      : data.orderType == 2
                      ? "续费"
                      : data.orderType == 3
                      ? "扩用户"
                      : "- -"
                  }}</span
                >

                <span class="text" v-if="data.productCategory == 3">{{
                  data.version == 1
                    ? "腾讯云"
                    : data.version == 2
                    ? "阿里云"
                    : "- -"
                }}</span>
              </el-form-item>
              <el-form-item
                label="用户数"
                v-if="
                  data.productCategory == 1 ||
                    data.productCategory == 2 ||
                    data.productCategory == 10
                "
              >
                <span class="text">{{ data.orderNumber }}</span>
              </el-form-item>
              <el-form-item
                label="购买年限"
                v-if="
                  (data.productCategory == 1 ||
                    data.productCategory == 2 ||
                    data.productCategory == 10) &&
                    data.orderType != 3
                "
              >
                <span class="text">{{ data.orderTerm }}</span>
              </el-form-item>
              <el-form-item
                label="增补年限"
                v-if="
                  (data.productCategory == 1 || data.productCategory == 2) &&
                    data.orderType != 3
                "
              >
                <span class="text">{{ data.supplementTerm }}</span>
              </el-form-item>
              <el-form-item
                label="下单年限"
                v-if="
                  (data.productCategory == 1 || data.productCategory == 2) &&
                    data.orderType != 3
                "
              >
                <span class="text">{{ data.totalTerm }}</span>
              </el-form-item>
              <el-form-item
                label="vip账号金额"
                v-if="data.productCategory == 10"
              >
                <span class="text">{{ data.vipAmount }}</span>
              </el-form-item>
              <el-form-item label="系统金额" v-if="data.productCategory == 10">
                <span class="text">{{ data.systemAmount }}</span>
              </el-form-item>
              <el-form-item label="合计金额">
                <span class="text">{{ data.amount }}</span>
              </el-form-item>
              <el-form-item label="交付状态">
                <span class="text">{{
                  data.status == 1
                    ? "未交付"
                    : data.status == 2
                    ? "部分交付"
                    : data.status == 3
                    ? "已交付"
                    : "- -"
                }}</span>
              </el-form-item>
              <el-form-item label="备注">
                <span class="text">{{ data.remark }}</span>
              </el-form-item>
            </el-form>
          </el-collapse-item>
        </el-collapse>
      </template>
      <template v-else>
        <div class="empty">
          <img src="@/assets/zanwushuju.png" />
          <span style="display:block">暂无数据～</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { orderList } from "@/api/user/ae/contract.js";
export default {
  data() {
    return {
      data: [],
    };
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      let data = {
        param: {
          contractId: this.$formDetails.state.data.id,
        },
        pageNum: 0,
        pageSize: 0,
      };
      orderList(data).then((res) => {
        this.data = res.data.list;
      });
    },
    title(data) {
      if (data.productCategory == 1) {
        return "腾讯企业邮箱";
      } else if (data.productCategory == 2) {
        return "阿里企业邮箱";
      } else if (data.productCategory == 3) {
        return "云产品";
      } else if (data.productCategory == 4) {
        return "网易企业邮箱";
      } else if (data.productCategory == 5) {
        return "网站";
      } else if (data.productCategory == 6) {
        return "投屏盒子";
      } else if (data.productCategory == 7) {
        return "微盘";
      } else if (data.productCategory == 8) {
        return "主机";
      } else if (data.productCategory == 9) {
        return "服务器";
      } else if (data.productCategory == 10) {
        return "会话存档";
      } else if (data.productCategory == 11) {
        return "企业微信";
      } else {
        return "- -";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.relation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #666666;
  }
  .content {
    overflow: auto;
    flex: 1;

    .el-form-item {
      margin-bottom: 0;
    }
  }
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
