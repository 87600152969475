import http from '@/utils/http.js'



// 合同列表
export function contractList(data) {
    return http({
        url: "/jasoboss/contract/list",
        method: "post",
        data
    })
}
// 新建合同
export function addContract(data) {
    return http({
        url: "/jasoboss/contract/add",
        method: "post",
        data
    })
}
// 关联订单列表
export function orderList(data) {
    return http({
        url: "/jasoboss/order/list",
        method: "post",
        data
    })
}
// id查询详情/jasoboss/contract/update
export function contractId(data) {
    return http({
        url: "/jasoboss/contract/get",
        method: "post",
        data
    })
}
// 合同编辑
export function contractEdit(data) {
    return http({
        url: "/jasoboss/contract/update",
        method: "post",
        data
    })
}
// 提交审核
export function commitAudit(data) {
    return http({
        url: "/jasoboss/contract/commit/audit",
        method: "post",
        data
    })
}
// 取消审核
export function cancelAudit(data) {
    return http({
        url: "/jasoboss/contract/cancel/audit",
        method: "post",
        data
    })
}
// 作废
export function contractDelete(data) {
    return http({
        url: "/jasoboss/contract/delete",
        method: "post",
        data
    })
}
// 所有的合同列表
export function contractAll(data) {
    return http({
        url: "/jasoboss/contract/list",
        method: "post",
        data
    })
}
// 合同审核
export function contractAudit(data) {
    return http({
        url: "/jasoboss/contract/audit",
        method: "post",
        data
    })
}
// 添加附件
export function addAnnex(data) {
    return http({
        url: "/jasoboss/file/local/upload/contract",
        method: "post",
        data
    })
} 
// 添加附件
export function addAttachment(data) {
    return http({
        url: "/jasoboss/contract/add/attachment",
        method: "post",
        data
    })
} 
// 删除合同
export function deleteBusinessLicense(data) {
    return http({
        url: "/jasoboss/contract/delete/businessLicense/url",
        method: "post",
        data
    })
}